import React from "react";

import Layout from "../components/layout";
import sidemailLogos from "../images/sidemail-logo-blackwhite.png";

export default () => {
	return (
		<Layout
			pageMeta={{
				title: "Press & Media Resources",
			}}>
			<main>
				<header className="blog text-center mb-90">
					<div className="headerWithBg mb-50">
						<div className="container">
							<h1>Press & Media Resources</h1>
						</div>
					</div>
				</header>

				<section className="container maxWidth-750 mb-70">
					<h2>About Sidemail</h2>
					<p className="">
						Sidemail is an all-in-one email delivery platform mainly focusing on
						SaaS businesses. It covers everything for delivering and managing
						emails – transactional emails, email marketing, email automation,
						contact profiles management, and subscribe forms. What developers
						and business owners appreciate the most is its easy integration,
						intuitive UI, and amazing customer care.
					</p>
					<p className="mb-40">
						Sidemail was founded in 2018 and is based in the EU.
					</p>

					<h3>Popular solutions</h3>
					<ul className="list-nonstyled">
						<li className="mb-20">
							<strong>
								<a href="/email-sending-api/">Email sending API</a>
							</strong>
							<div className="mt-6">
								Start sending transactional emails like password reset or
								welcome emails from your application in just a few minutes –
								Sidemail makes it incredibly easy to create, maintain, analyze,
								and deliver beautiful transactional emails.
							</div>
						</li>

						<li className="mb-20">
							<strong>
								<a href="/targeted-email-messages/">
									Product updates & newsletter
								</a>
							</strong>
							<div className="mt-6">
								Send product update emails (newsletters) and let your users know
								what’s new in your product with Messenger. It’s as easy as
								sending an email directly from your Gmail account, except you
								can send to all users and see the stats.
							</div>
						</li>

						<li className="mb-20">
							<strong>
								<a href="/automated-email-sequences/">
									Automated email sequences
								</a>
							</strong>
							<div className="mt-6">
								Set up email automation to deliver your email or sequence of
								emails when a defined event happens — for example, user sign-up.
								Use it to onboard new users, convert them into paying customers,
								and minimize churn.
							</div>
						</li>

						<li className="mb-20">
							<strong>
								<a href="/subscribe-forms/">Hosted subscribe forms</a>
							</strong>
							<div className="mt-6">
								Collect subscribers effortlessly without touching a code.
								Sidemail subscribe forms are simple to set up, easy to share,
								and you can customize them to fit your brand.
							</div>
						</li>

						<li className="mb-20">
							<strong>
								<a href="/contact-profiles/">Contact management</a>
							</strong>
							<div className="mt-6">
								All your subscribers in one neatly organized place that always
								has enough room. Collect email addresses, and grow your email
								list with simple to set up subscribe forms that are easy to
								share.
							</div>
						</li>

						<li className="mb-20">
							<strong>
								<a href="/no-code-email-editor/">No-code email editor</a>
							</strong>
							<div className="mt-6">
								Create responsive, well-tested email templates without code –
								Sidemail no-code editor is the simplest way to craft
								professionally-looking, responsive email templates that won't
								break in Outlook. Just upload a logo, pick brand colors, and
								you're ready to start sending in style.
							</div>
						</li>
					</ul>
				</section>

				<section className="container maxWidth-750 mb-70">
					<h2>Logo</h2>
					<p className="mb-40">
						Download{" "}
						<a
							href="https://drive.google.com/drive/folders/1TQBX8d8yB08v5dldnFAagJ3RTC9lcjiC?usp=sharing"
							target="_blank">
							Sidemail branding assets at the link here
						</a>
					</p>

					<img
						src={sidemailLogos}
						style={{
							boxShadow: "0px 0px 28px 0 rgba(0, 0, 0, 0.1)",
							borderRadius: "10px",
							marginBottom: "50px",
						}}
					/>

					<h3>Logo variants</h3>
					<ul className="mb-50">
						<li className="mb-15">
							<strong>Primary variant</strong> – The primary logo variant
							combines the Sidemail symbol and wordmark. It is the main Sidemail
							logo and should be used in this form whenever possible.
						</li>
						<li className="">
							<strong>Invert variant</strong> – The invert logo variant features
							a white wordmark and should be used on dark backgrounds.
						</li>
					</ul>

					<h3>Legal disclaimer</h3>
					<p>
						By downloading Sidemail brand assets, you understand, agree, and
						hereby represent that:
					</p>

					<ol className="mb-10">
						<li>
							The use of the content is in connection with an article or video
							about Sidemail.
						</li>
						<li>Your use must not mislead users.</li>
						<li>
							Brand assets are Sidemail’s exclusive property. You may not modify
							logos and other brand assets in any way. Where used on a web page,
							our brand assets, as well as the mention of our name, should
							include embedded hyperlinks to our homepage:{" "}
							<a href="/">https://sidemail.io/</a>.
						</li>
						<li>
							Brand assets must be used in a respectful manner and may not be
							used in a way that harms Sidemail or damages its reputation and
							goodwill.
						</li>
					</ol>

					<p>
						Furthermore, by using the Sidemail brand assets and resources, you
						agree to the Sidemail Terms of Service, Privacy Policy, and all
						other Sidemail rules and policies.
					</p>
				</section>

				<section className="container maxWidth-750 mb-50">
					<h2>Contact</h2>
					<p>
						For press inquiries, please reach out to us at{" "}
						<a href="mailto:press@sidemail.io">press@sidemail.io</a>.
					</p>
				</section>
			</main>
		</Layout>
	);
};
